var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-column cate" }, [
    _c("div", { staticClass: "ywml" }, [
      _c(
        "div",
        { staticClass: "node-actions" },
        [
          _c("el-input", {
            staticStyle: { width: "120px" },
            attrs: { placeholder: "请输入", size: "mini" },
            model: {
              value: _vm.inputtext1,
              callback: function ($$v) {
                _vm.inputtext1 = $$v
              },
              expression: "inputtext1",
            },
          }),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.add(0)
                },
              },
            },
            [_vm._v("添加")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "tree-container1",
          staticStyle: {
            height: "700px",
            "overflow-y": "auto",
            width: "300px",
          },
        },
        [
          _c("div", { staticClass: "tree-node" }, [
            _c(
              "div",
              {
                staticClass: "node-header",
                on: {
                  click: function ($event) {
                    return _vm.toggleCollapse(1)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-folder aac",
                  style: { color: _vm.getRandomColor() },
                }),
                _c("span", { staticClass: "node-name" }, [_vm._v("行业")]),
                _c("i", {
                  class: [
                    "el-icon-arrow-right",
                    _vm.isCollapsed1 ? "collapsed" : "",
                  ],
                }),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isCollapsed1,
                    expression: "!isCollapsed1",
                  },
                ],
                staticClass: "node-children",
              },
              _vm._l(_vm.list, function (parent, parentIndex) {
                return _c(
                  "div",
                  {
                    key: parentIndex,
                    staticClass: "child-node",
                    style: _vm.parentStyle(parent, parentIndex),
                    attrs: {
                      "header-cell-style": {
                        background: "#eef1f6",
                        color: "#606266",
                      },
                      "row-class-name": _vm.setTableRowClassName,
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-folder aac",
                      style: { color: _vm.getRandomColor() },
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "node-name",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.loadChildren(parent, parentIndex)
                          },
                        },
                      },
                      [_vm._v(_vm._s(parent.system_category_name))]
                    ),
                    _c("i", {
                      staticClass: "el-icon-close",
                      staticStyle: { cursor: "pointer", "margin-left": "10px" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleDelete(parent)
                        },
                      },
                    }),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: parent.isCollapsed,
                            expression: "parent.isCollapsed",
                          },
                        ],
                        staticClass: "node-children",
                      },
                      _vm._l(parent.children, function (child, childIndex) {
                        return _c(
                          "div",
                          {
                            key: childIndex,
                            staticClass: "child-node axx",
                            style: {
                              height: "20px",
                              marginBottom: "10px !important",
                              fontWeight: _vm.rowFontWeight2(childIndex),
                            },
                            attrs: {
                              "header-cell-style": {
                                background: "#eef1f6",
                                color: "#606266",
                              },
                              "row-class-name": _vm.setTableRowClassName2,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.rowClickFirstTable4(
                                  child,
                                  childIndex
                                )
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-folder aac",
                              style: { color: _vm.getRandomColor() },
                            }),
                            _c("span", { staticClass: "node-name" }, [
                              _vm._v(_vm._s(child.system_category_name)),
                            ]),
                            _c("i", {
                              staticClass: "el-icon-close",
                              staticStyle: {
                                cursor: "pointer",
                                "margin-left": "10px",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleDelete(child)
                                },
                              },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "node-actions" },
        [
          _c("el-input", {
            staticStyle: { width: "120px" },
            attrs: { placeholder: "请输入", size: "mini" },
            model: {
              value: _vm.inputtext2,
              callback: function ($$v) {
                _vm.inputtext2 = $$v
              },
              expression: "inputtext2",
            },
          }),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.add(1)
                },
              },
            },
            [_vm._v("添加")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "node-actions xxx" },
        [
          _c("el-input", {
            staticStyle: { width: "120px" },
            attrs: { placeholder: "请输入", size: "mini" },
            model: {
              value: _vm.inputtext3,
              callback: function ($$v) {
                _vm.inputtext3 = $$v
              },
              expression: "inputtext3",
            },
          }),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.add(2)
                },
              },
            },
            [_vm._v("添加")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "tree-container2",
          staticStyle: {
            height: "700px",
            "overflow-y": "auto",
            width: "300px",
          },
        },
        [
          _c("div", { staticClass: "tree-node" }, [
            _c(
              "div",
              {
                staticClass: "node-header",
                on: {
                  click: function ($event) {
                    return _vm.toggleCollapse(2)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-folder aac",
                  style: { color: _vm.getRandomColor() },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "node-name" }, [_vm._v("管理体系")]),
                _c("i", {
                  class: [
                    "el-icon-arrow-right",
                    _vm.isCollapsed2 ? "collapsed" : "",
                  ],
                }),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isCollapsed2 && _vm.showList4Content,
                    expression: "!isCollapsed2 && showList4Content",
                  },
                ],
                staticClass: "node-children",
              },
              _vm._l(_vm.list4, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "child-node",
                    style: {
                      height: "20px",
                      marginBottom: "10px !important",
                      fontWeight: _vm.rowFontWeight3(index),
                    },
                    attrs: {
                      "header-cell-style": {
                        background: "#eef1f6",
                        color: "#606266",
                      },
                      "row-class-name": _vm.setTableRowClassName2,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.rowClickFirstTable5(item, index)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-folder aac",
                      style: { color: _vm.getRandomColor() },
                    }),
                    _c("span", { staticClass: "node-name" }, [
                      _vm._v(_vm._s(item.system_category_name)),
                    ]),
                    _c("i", {
                      staticClass: "el-icon-close",
                      staticStyle: { cursor: "pointer", "margin-left": "10px" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleDelete(item)
                        },
                      },
                    }),
                  ]
                )
              }),
              0
            ),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "folder-container" }, [
      _c(
        "div",
        {
          staticClass: "folder-icon",
          on: {
            click: function ($event) {
              return _vm.navigateToTemplate("管理手册")
            },
          },
        },
        [
          _c("img", {
            staticClass: "folder-image bbc",
            staticStyle: { width: "85px", height: "85px" },
            attrs: { src: require("../../../public/wenjian.png") },
          }),
          _c("div", { staticClass: "folder-label" }, [_vm._v("管理手册")]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "folder-icon",
          on: {
            click: function ($event) {
              return _vm.navigateToTemplate("程序文件")
            },
          },
        },
        [
          _c("img", {
            staticClass: "folder-image bbc",
            staticStyle: { width: "85px", height: "85px" },
            attrs: { src: require("../../../public/wenjian.png") },
          }),
          _c("div", { staticClass: "folder-label" }, [_vm._v("程序文件")]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "folder-icon",
          on: {
            click: function ($event) {
              return _vm.navigateToTemplate("作业文件")
            },
          },
        },
        [
          _c("img", {
            staticClass: "folder-image bbc",
            staticStyle: { width: "85px", height: "85px" },
            attrs: { src: require("../../../public/wenjian.png") },
          }),
          _c("div", { staticClass: "folder-label" }, [_vm._v("作业文件")]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "folder-icon",
          on: {
            click: function ($event) {
              return _vm.navigateToTemplate("运行记录")
            },
          },
        },
        [
          _c("img", {
            staticClass: "folder-image bbc",
            staticStyle: { width: "85px", height: "85px" },
            attrs: { src: require("../../../public/wenjian.png") },
          }),
          _c("div", { staticClass: "folder-label" }, [_vm._v("运行记录")]),
        ]
      ),
    ]),
    _c("div", { staticClass: "vertical-line2" }),
    _c("img", {
      staticClass: "folder-image vvv",
      staticStyle: { width: "400px", height: "400px" },
      attrs: { src: require("../../../public/bangong.png") },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }