<!--
 * @description: 代码生成器自动生成
 * @author: liyubao | xufuxing
 * @version: 1.0
-->
<template>
  <div class="flex-column cate">
    <!-- <h3 style="color: #8c8989">业务目录管理</h3> -->
    <div class="ywml">
      <div class="node-actions">
        <el-input v-model="inputtext1" placeholder="请输入" size="mini" style="width: 120px;"></el-input>
        <el-button @click="add(0)" size="mini" type="primary">添加</el-button>
      </div>
      <div class="tree-container1" style="height: 700px; overflow-y: auto; width: 300px;">  
        <div class="tree-node">
          <div class="node-header" @click="toggleCollapse(1)">         
            <i class="el-icon-folder aac" :style="{ color: getRandomColor()}"></i> 
              <span class="node-name">行业</span>
              <i :class="['el-icon-arrow-right', isCollapsed1 ? 'collapsed' : '']"></i>
          </div>

          <!-- <div v-show="!isCollapsed1" class="node-children">
            <div v-for="(parent, parentIndex) in list" 
              :key="parentIndex" 
              :style="parentStyle(parent, parentIndex)"
              :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
              :row-class-name="setTableRowClassName"
              @click="rowClickFirstTable(parent, parentIndex)"
              class="child-node">
              
              <i class="el-icon-folder aac" :style="{ color: getRandomColor() }"></i> 
              <span class="node-name" @click="loadChildren(parent, parentIndex)">{{ parent.system_category_name }}</span>
              <i class="el-icon-close" @click="handleDelete(parent)" style="cursor: pointer; margin-left: 10px;"></i>
              
              <div v-show="parent.isCollapsed" class="node-children">
                <div v-for="(child, childIndex) in parent.children" 
                    :key="childIndex" 
                    :style="{ height: '20px', marginBottom: '10px !important', fontWeight: rowFontWeight3(childIndex) }"
                    :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                    :row-class-name="setTableRowClassName2"
                    class="child-node axx"
                    @click="rowClickFirstTable4(child, childIndex)">
                    
                    <i class="el-icon-folder aac" :style="{ color: getRandomColor() }"></i> 
                    <span class="node-name">{{ child.system_category_name }}</span>
                    <i class="el-icon-close" @click="handleDelete(child)" style="cursor: pointer; margin-left: 10px;"></i>
                </div>
              </div>
            </div>
          </div> -->
          <div v-show="!isCollapsed1" class="node-children">
            <div v-for="(parent, parentIndex) in list" 
              :key="parentIndex" 
              :style="parentStyle(parent, parentIndex)"
              :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
              :row-class-name="setTableRowClassName"
              class="child-node">
              
              <i class="el-icon-folder aac" :style="{ color: getRandomColor() }"></i> 
              <span class="node-name" @click.stop="loadChildren(parent, parentIndex)">{{ parent.system_category_name }}</span>
              <i class="el-icon-close" @click.stop="handleDelete(parent)" style="cursor: pointer; margin-left: 10px;"></i>
              
              <div v-show="parent.isCollapsed" class="node-children">
                  <div v-for="(child, childIndex) in parent.children" 
                      :key="childIndex" 
                      :style="{ height: '20px', marginBottom: '10px !important', fontWeight: rowFontWeight2(childIndex) }"
                      :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                      :row-class-name="setTableRowClassName2"
                      class="child-node axx"
                      @click="rowClickFirstTable4(child, childIndex)">
                      
                      <i class="el-icon-folder aac" :style="{ color: getRandomColor() }"></i> 
                      <span class="node-name">{{ child.system_category_name }}</span>
                      <i class="el-icon-close" @click.stop="handleDelete(child)" style="cursor: pointer; margin-left: 10px;"></i>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="node-actions">
        <el-input v-model="inputtext2" placeholder="请输入" size="mini" style="width: 120px;"></el-input>
        <el-button @click="add(1)" size="mini" type="primary">添加</el-button>
        <!-- <el-button size="mini" type="success" @click="update(1)">编辑</el-button> -->
      </div>
      <div class="node-actions xxx">
        <el-input v-model="inputtext3" placeholder="请输入" size="mini" style="width: 120px;"></el-input>
        <el-button @click="add(2)" size="mini" type="primary">添加</el-button>
        <!-- <el-button size="mini" type="success" @click="update(1)">编辑</el-button> -->
      </div>
      <div class="tree-container2"  style="height: 700px; overflow-y: auto; width: 300px;">
        <div class="tree-node">
          <div class="node-header" @click="toggleCollapse(2)">
            <i class="el-icon-folder aac" :style="{ color: getRandomColor() }"></i> <!-- 文件夹图标 -->
            <span class="node-name">管理体系</span>
            <i :class="['el-icon-arrow-right', isCollapsed2 ? 'collapsed' : '']"></i>
          </div>

          <div v-show="!isCollapsed2 && showList4Content" class="node-children">
            <div v-for="(item, index) in list4" 
                :key="index" 
                :style="{ height: '20px', marginBottom: '10px !important', fontWeight: rowFontWeight3(index) }"
                :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                :row-class-name="setTableRowClassName2"
                class="child-node"
                @click="rowClickFirstTable5(item, index)">
                <i class="el-icon-folder aac" :style="{ color: getRandomColor() }"></i> 
                <span class="node-name">{{ item.system_category_name }}</span>
                <i class="el-icon-close" @click.stop="handleDelete(item)" style="cursor: pointer; margin-left: 10px;"></i>
            </div>
          </div>

          <!-- <div v-show="!isCollapsed2" class="node-children">
            <div v-for="(parent, parentIndex) in list3" 
              :key="parentIndex" 
              :style="parentStyle(parent, parentIndex)"
              :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
              :row-class-name="setTableRowClassName2"
              @click="rowClickFirstTable4(parent, parentIndex)"
              class="child-node">
              
              <i class="el-icon-folder aac" :style="{ color: getRandomColor() }"></i> 
              <span class="node-name" @click="loadChildren(parent, parentIndex)">{{ parent.system_category_name }}</span>
              <i class="el-icon-close" @click="handleDelete(parent)" style="cursor: pointer; margin-left: 10px;"></i>
              
              <div v-show="parent.isCollapsed" class="node-children">
                <div v-for="(child, childIndex) in parent.children" 
                    :key="childIndex" 
                    :style="{ height: '20px', marginBottom: '10px !important', fontWeight: rowFontWeight3(childIndex) }"
                    :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                    :row-class-name="setTableRowClassName2"
                    class="child-node axx"
                    @click="rowClickFirstTable5(child, childIndex)">
                    
                    <i class="el-icon-folder aac" :style="{ color: getRandomColor() }"></i> 
                    <span class="node-name">{{ child.system_category_name }}</span>
                    <i class="el-icon-close" @click="handleDelete(child)" style="cursor: pointer; margin-left: 10px;"></i>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="folder-container">
      <div class="folder-icon" @click="navigateToTemplate('管理手册')">
        <img src="../../../public/wenjian.png" class="folder-image bbc" style="width: 85px; height: 85px;"> 
        <div class="folder-label">管理手册</div>
      </div>
      

      <div class="folder-icon" @click="navigateToTemplate('程序文件')">
        <img src="../../../public/wenjian.png" class="folder-image bbc" style="width: 85px; height: 85px;"> 
        <div class="folder-label">程序文件</div>
      </div>
      

      <div class="folder-icon" @click="navigateToTemplate('作业文件')">
        <img src="../../../public/wenjian.png" class="folder-image bbc" style="width: 85px; height: 85px;"> 
        <div class="folder-label">作业文件</div>
      </div>
      

      <div class="folder-icon" @click="navigateToTemplate('运行记录')">
        <img src="../../../public/wenjian.png" class="folder-image bbc" style="width: 85px; height: 85px;"> 
        <div class="folder-label">运行记录</div>
      </div>
      
    </div>
    <div class="vertical-line2"></div>
    <img src="../../../public/bangong.png" class="folder-image vvv" style="width: 400px; height: 400px;"> 
  </div>
</template>
<script>
import * as mzysystem_category2s from "@/api/mzysystem_category2s";
import * as mzysystem_categorys from "@/api/mzysystem_categorys";
import waves from "@/directive/waves"; // 水波纹指令
// import Sticky from "@/components/Sticky";
// import permissionBtn from "@/components/PermissionBtn";
// import Pagination from "@/components/Pagination";
import elDragDialog from "@/directive/el-dragDialog";
// import { defaultVal } from "@/utils/index";
import extend from "@/extensions/delRows.js";
// import AuthForm from "../../components/Base/AuthForm.vue";
// import AuthTable from "../../components/Base/AuthTable.vue";
export default {
  name: "mzysystem_category",
  // components: { Sticky, permissionBtn, Pagination, AuthTable, AuthForm },
  directives: {
    waves,
    elDragDialog,
  },
  mixins: [extend],
  data() {
    return {
      // 一级的数据
      list: [], // 子目录列表
      isCollapsed1: false, // 是否折叠
      isCollapsed2: false, // 是否折叠
      isCollapsed3: true, // 是否折叠
      isCollapsed4: true, // 是否折叠
      isCollapsed5: true, // 是否折叠
      isCollapsed6: true, // 是否折叠
      selectedRow: {}, //选中行
      inputtext1: "",
      // 二级的数据
      list3: null, //数据源
      list4: null,
      selectedRow2: {}, //选中行
      inputtext2: "",
      // 四级文件
      ServiceDirectoryList1: [],
      ServiceDirectoryList2: [],
      ServiceDirectoryList3: [],
      ServiceDirectoryList4: [],
      inputtext3: "",
      inputtext4: "",
      inputtext5: "",
      inputtext6: "",
      selectedRow3: {}, //选中行
      clickedRowIndex: -1, // 存储当前点击行的索引，默认为 -1 表示未点击任何行
      clickIndex:-1,
      click3:-1,
      confirmDelete: false,
      listQuery: {
        // 查询条件
        page: 1,
        limit: 20,
        key: undefined,
      },
    };
  },
  created() {
    //开始先拿第一层数据源
    this.getList();
  },
  methods: {
    loadChildrenFromBackend(item) {
      // Example API call simulation
      // This should be replaced with your actual API call logic
      setTimeout(() => {
        // Simulate an API response setting list4
        // this.list4 = [
        //   { parentId: item.id, system_category_name: 'Child 1 of ' + item.system_category_name },
        //   { parentId: item.id, system_category_name: 'Child 2 of ' + item.system_category_name }
        // ];

        // Find the parent in list3 and set its children
        const parentIndex = this.list3.findIndex(parent => parent.id === item.id);
        if (parentIndex !== -1) {
          this.$set(this.list3[parentIndex], 'children', this.list4);
          this.$set(this.list3[parentIndex], 'isCollapsed', !this.list3[parentIndex].isCollapsed);
        }
      }, 500);
    },
    // loadChildren(parent, index) {
    //   this.selectedRow = parent;
    //   if (!parent.children) {
    //     this.rowClickFirstTable(parent);
    //   } else {
    //     this.$set(this.list3[index], 'isCollapsed', !this.list3[index].isCollapsed);
    //   }
    // },
    loadChildren(parent, index) {
      this.selectedRow = parent;
      if (!parent.children || parent.children.length === 0) {
          this.rowClickFirstTable(parent, index);
          this.showList4Content = false; // 隐藏 list4 的内容
      } else {
          this.rowClickFirstTable(parent, index);
          this.showList4Content = true; // 显示 list4 的内容
      }
    },



    parentStyle(parent, index) {
      return {
        marginBottom: '10px',
        fontWeight: this.rowFontWeight(index),
        height: parent.isCollapsed ? 'auto' : '20px',
        overflow: parent.isCollapsed ? 'visible' : 'hidden'
      };
    },
    getRandomColor() {
      // 主要颜色数组
      const colors = ['#ff0000', '#ffff00', '#0000ff', '#00ff00']; // 红黄蓝绿

      // 从主要颜色数组中随机选择一个颜色返回
      return colors[Math.floor(Math.random() * colors.length)];
    },
    toggleCollapse(index) {
      this['isCollapsed' + index] = !this['isCollapsed' + index];
    },
    navigateToTemplate(folderName) {
      //this.$router.push({ name: '模板管理', params: { folderName } });
      console.log(folderName);
    },
    // 返回行的字体粗细样式
    rowFontWeight(index) {
      return index === this.clickedRowIndex ? 'bold' : 'normal';
    },
    rowFontWeight2(index) {
      return index === this.clickIndex ? 'bold' : 'normal';
    },
    rowFontWeight3(index) {
      return index === this.click3 ? 'bold' : 'normal';
    },
    // 通用
    add(type) {
      var temp = {};
      // var that = this;
      switch (type) {
        case 0:
          if (this.inputtext1 == "" || this.inputtext1 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          temp = {
            system_category_name: this.inputtext1,
          };

          mzysystem_categorys.add(temp).then(() => {
            this.dialogFormVisible = false;
            //this.getList();
            this.inputtext1 = "";
            this.$notify({
              title: "成功",
              message: "创建成功",
              type: "success",
              duration: 2000,
            });
          });
          //window.location.reload();
          this.getList();
          break;
        case 1:
          if (this.inputtext2 == "" || this.inputtext2 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          temp = {
            system_category_name: this.inputtext2,
            parent_id: this.selectedRow.id,
          };
          console.log("此时id为:" + this.selectedRow.id);
          if(this.selectedRow.id == null){
            alert("未选择行业一级目录");
            return;
          }
          mzysystem_categorys.add(temp).then((response) => {
            if (response !== null) {
              // 如果返回值不是null，执行以下操作
              this.dialogFormVisible = false;

              console.log(22222);
              this.getSecondarydata(this.selectedRow.id);

              this.inputtext2 = "";
              this.$notify({
                title: "成功",
                message: "创建成功",
                type: "success",
                duration: 2000,
              });
            } else {
              // 如果返回值是null，弹出提示信息
              alert("未选择行业一级目录");
              return;
            }
          });
          //window.location.reload();
          this.getList();
          break;
        case 2:
          if (this.inputtext3 == "" || this.inputtext3 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          temp = {
            system_category_name: this.inputtext3,
            parent_id: this.selectedRow.id,
          };
          console.log("此时id2为:" + this.selectedRow.id);
          if(this.selectedRow.id == null){
            alert("未选择行业二级目录");
            return;
          }
          mzysystem_categorys.add1(temp).then((response) => {
            if (response !== null) {
              // 返回值不是 null 的情况下，执行以下操作
              console.log(33333);
              this.getSecondarydata2(this.selectedRow.id);

              this.inputtext3 = "";
              this.$notify({
                title: "成功",
                message: "创建成功",
                type: "success",
                duration: 2000,
              });
            } else {
              // 返回值是 null 的情况下，弹出提示信息
              alert("未正确选择行业二级目录");
              return;
            }
          });
          //window.location.reload();
          this.getList();
          break;
        case 3:
          if (this.inputtext4 == "" || this.inputtext4 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          temp = {
            template_name: this.inputtext4,
            parent_id: this.selectedRow2.id,
            template_level: 2,
          };
          mzysystem_category2s.add(temp).then(() => {
            console.log(999999);
            this.getSecondarydata2(this.selectedRow2.id);

            this.inputtext4 = "";
            this.$notify({
              title: "成功",
              message: "创建成功",
              type: "success",
              duration: 2000,
            });
          });
          break;
        case 4:
          if (this.inputtext5 == "" || this.inputtext5 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          temp = {
            template_name: this.inputtext5,
            parent_id: this.selectedRow2.id,
            template_level: 3,
          };
          mzysystem_category2s.add(temp).then(() => {
            console.log(999999);
            this.getSecondarydata2(this.selectedRow2.id);

            this.inputtext4 = "";
            this.$notify({
              title: "成功",
              message: "创建成功",
              type: "success",
              duration: 2000,
            });
          });
          break;
        case 5:
          if (this.inputtext6 == "" || this.inputtext6 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          temp = {
            template_name: this.inputtext6,
            parent_id: this.selectedRow2.id,
            template_level: 4,
          };
          mzysystem_category2s.add(temp).then(() => {
            console.log(999999);
            this.getSecondarydata2(this.selectedRow2.id);

            this.inputtext4 = "";
            this.$notify({
              title: "成功",
              message: "创建成功",
              type: "success",
              duration: 2000,
            });
          });
          break;
      }
    },
    update(type) {
      console.log(type);
      // var temp = {};
      switch (type) {
        case 0:
          if (this.inputtext1 == "" || this.inputtext1 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          this.selectedRow.system_category_name = this.inputtext1;
          // temp = {
          //   system_category_name: this.inputtext1,
          // };
          mzysystem_categorys.update(this.selectedRow).then(() => {
            this.dialogFormVisible = false;
            this.getList();
            this.inputtext1 = "";
            this.$notify({
              title: "成功",
              message: "更新",
              type: "success",
              duration: 2000,
            });
          });
          break;
        case 1:
          if (this.inputtext2 == "" || this.inputtext2 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          this.selectedRow2.system_category_name = this.inputtext2;
          mzysystem_categorys.update(this.selectedRow2).then(() => {
            this.dialogFormVisible = false;
            this.getList();
            this.inputtext1 = "";
            this.$notify({
              title: "成功",
              message: "更新",
              type: "success",
              duration: 2000,
            });
          });
          break;
        case 2:
          if (this.inputtext3 == "" || this.inputtext3 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          this.selectedRow3.template_name = this.inputtext3;
          mzysystem_categorys.update(this.selectedRow3).then(() => {
            this.getSecondarydata2(this.selectedRow3.id);
            this.inputtext4 = "";
            this.$notify({
              title: "成功",
              message: "更新成功",
              type: "success",
              duration: 2000,
            });
          });
          break;
        case 3:
          if (this.inputtext4 == "" || this.inputtext4 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          this.selectedRow3.template_name = this.inputtext4;
          mzysystem_category2s.update(this.selectedRow3).then(() => {
            this.getSecondarydata2(this.selectedRow2.id);
            this.inputtext4 = "";
            this.$notify({
              title: "成功",
              message: "更新成功",
              type: "success",
              duration: 2000,
            });
          });
          break;
        case 4:
          if (this.inputtext5 == "" || this.inputtext5 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          this.selectedRow3.template_name = this.inputtext5;
          mzysystem_category2s.update(this.selectedRow3).then(() => {
            this.getSecondarydata2(this.selectedRow2.id);
            this.inputtext4 = "";
            this.$notify({
              title: "成功",
              message: "更新成功",
              type: "success",
              duration: 2000,
            });
          });
          break;
        case 5:
          if (this.inputtext6 == "" || this.inputtext6 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }

          this.selectedRow3.template_name = this.inputtext6;
          mzysystem_category2s.update(this.selectedRow3).then(() => {
            this.getSecondarydata2(this.selectedRow2.id);
            this.inputtext4 = "";
            this.$notify({
              title: "成功",
              message: "更新成功",
              type: "success",
              duration: 2000,
            });
          });
          break;
      }
    },
    handleDelete(row) {
      this.empty();
      console.log("handleDelete");
      var data = [row.id];
      mzysystem_categorys.del(data).then(() => {
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000,
        });
      });  
      //this.getList();
      //window.location.reload();
      this.getList();
      // 操作完成后，重置确认删除状态
      this.confirmDelete = false;
    },
    // handleDelete2(row) {
    //   this.empty();
    //   this.getList();
    //   console.log("handleDelete");
    //   var data = [row.id];
    //   console.log("row_id",data);
    //   mzysystem_category2s.del(data).then(() => {
    //     this.$notify({
    //       title: "成功",
    //       message: "删除成功",
    //       type: "success",
    //       duration: 2000,
    //     });
    //   });
    // },
    empty() {
      this.selectedRow = {};
      this.selectedRow2 = {};
      this.selectedRow3 = {};
      this.list3 = [];
      this.ServiceDirectoryList1 = [];
      this.ServiceDirectoryList2 = [];
      this.ServiceDirectoryList3 = [];
      this.ServiceDirectoryList4 = [];
    },

    // 第一层
    getList() {
      //数据获取
      // this.listLoading = true;
      mzysystem_categorys.getList(this.listQuery).then((response) => {
        this.list = response.data;
        // this.list = response.data.sort((a, b) => {
        //   return new Date(b.add_time) - new Date(a.add_time); // 按照日期从大到小排序
        // });
        console.log("list", this.list);
        response.columnFields.forEach((item) => {
          // 首字母小写
          item.columnName =
            item.columnName.substring(0, 1).toLowerCase() +
            item.columnName.substring(1);
        });
      });
    },
    // rowClickFirstTable(row, index) {
    //   //行点击
    //   this.getSecondarydata(row.id);
    //   //this.getSecondarydata2(row.id);
    //   this.clickedRowIndex = index; // 更新当前点击行的索引
    //   console.log("hang_id",row.id);
    //   // 点击行

    //   this.selectedRow = row;
    // },
    rowClickFirstTable(row, index) {
      // 先将所有父级元素的 isCollapsed 设置为 false
      this.list.forEach((parent) => {
          if (parent !== row) {
              parent.isCollapsed = false;
              // 同时将所有子元素的 isCollapsed 设置为 false
              if (parent.children) {
                  parent.children.forEach((child) => {
                      child.isCollapsed = false;
                  });
              }
          }
      });

      // 切换当前点击的父级元素的 isCollapsed 属性
      row.isCollapsed = !row.isCollapsed;

      // 更新当前点击行的索引
      this.clickedRowIndex = index;

      // 如果展开（isCollapsed 为 true），则获取子项数据
      if (row.isCollapsed && (!row.children || row.children.length === 0)) {
          this.getSecondarydata(row.id, row);
      }

      console.log("hang_id", row.id);
      //this.getSecondarydata2(row.id);
      this.selectedRow = row;
    },


    rowClickFirstTable4(row, index) {
      // 行点击
      this.getSecondarydata2(row.id);
      this.clickIndex = index; // 更新当前点击行的索引
      console.log("hang_id2", row.id);
      // 点击行
      this.selectedRow = row;

      // 显示隐藏的 div 内容
      this.showList4Content = true;
    },

    rowClickFirstTable5(row, index) {
      //行点击
      //this.getSecondarydata(row.id);
      this.click3 = index; // 更新当前点击行的索引
      console.log("hang_id3",row.id);
      // 点击行

      this.selectedRow3 = row;
    },
    setTableRowClassName({ row, rowIndex }) {
      // 设置行的类名
      console.log(rowIndex);
      return row === this.selectedRow ? "selected-row" : "";
    },
    //第二层
    setTableRowClassName2({ row, rowIndex }) {
      // 设置行的类名
      console.log(rowIndex);
      return row === this.selectedRow2 ? "selected-row" : "";
    },
    setTableRowClassName3({ row, rowIndex }) {
      // 设置行的类名
      console.log(rowIndex);
      return row === this.selectedRow3 ? "selected-row" : "";
    },
    // getSecondarydata2(id) {
    //   var listQuery = { parent_id: id, page: 1, limit: 999, key: undefined };
    //   var that = this;
    //   mzysystem_category2s.LoadList(listQuery).then((response) => {
    //     console.log(22222);
    //     that.ServiceDirectoryList1 = response.data1.data;
    //     that.ServiceDirectoryList2 = response.data2.data;
    //     that.ServiceDirectoryList3 = response.data3.data;
    //     that.ServiceDirectoryList4 = response.data4.data;
    //   });
    // },
    getSecondarydata2(id) {
      var listQuery = { parent_id: id, page: 1, limit: 999, key: undefined };
      mzysystem_categorys.GetListByParent_id(listQuery).then((response) => {
        this.list4 = response.data;
        response.columnFields.forEach((item) => {
          // 首字母小写
          item.columnName =
            item.columnName.substring(0, 1).toLowerCase() +
            item.columnName.substring(1);
        });
      });
    },
    rowClickFirstTable2(row) {
      //行点击
      this.getSecondarydata2(row.id);
      // 点击行
      this['isCollapsed' + 3] = !this['isCollapsed' + 3];

      this.selectedRow2 = row;
      this.loadChildrenFromBackend(row);
    },
    rowClickFirstTable3(row) {
      //行点击
      this.getSecondarydata2(row.id);
      // 点击行

      this.selectedRow3 = row;
    },
    //四层文件管理
    // getSecondarydata(id) {
    //   var listQuery = { parent_id: id, page: 1, limit: 999, key: undefined };
    //   mzysystem_categorys.GetListByParent_id(listQuery).then((response) => {
    //     this.list3 = response.data;
    //     response.columnFields.forEach((item) => {
    //       // 首字母小写
    //       item.columnName =
    //         item.columnName.substring(0, 1).toLowerCase() +
    //         item.columnName.substring(1);
    //     });
    //   });
    // },
    getSecondarydata(id, parent) {
      var listQuery = { parent_id: id, page: 1, limit: 999, key: undefined };
      mzysystem_categorys.GetListByParent_id(listQuery).then((response) => {
          // 确保父元素有 children 属性并赋值
          this.$set(parent, 'children', response.data);

          response.columnFields.forEach((item) => {
              item.columnName =
                  item.columnName.substring(0, 1).toLowerCase() +
                  item.columnName.substring(1);
          });
      });
    }
  },
};
</script>
<style lang="scss">
@import "../../styles/cate.scss";
.dialog-mini .el-select {
  width: 100%;
}
.selected {
  background: #f3f3f3;
  color: #14bf96;
}
.selected-row {
  background: #f3f3f3;
  color: #14bf96;
}
.selected-row {
  background: #f3f3f3;
  color: #14bf96;
}
.havetemplate {
  background: #1dcb13;
}
.tables {
  max-height: 300px;
  overflow-y: auto !important;
}
.tree-container {
  font-family: Arial, sans-serif;
}

.container {
  position: relative;
}

.tree-container1 {
  position: absolute;
  top: 30;
  left: 10;
  width: 30%;
}

.vertical-line1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 23%; /* 调整竖线的位置 */
  width: 1px;
  background-color: #000;
  content: "";
}

.vertical-line2 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%; /* 调整竖线的位置 */
  width: 1px; /* 调整竖线的宽度 */
  background-color: #d3d3d3; /* 设置竖线颜色为浅灰色 */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* 添加阴影效果 */
  content: "";
}


.tree-container2 {
  position: absolute;
  top: 30;
  left: 24%; /* 调整第二个部分的位置 */
  width: 69%; /* 调整第二个部分的宽度 */
}

.tree-container3 {
  position: absolute;
  top: 30;
  left: 45%; /* 调整第二个部分的位置 */
  width: 109%; /* 调整第二个部分的宽度 */
}

.node-header {
  cursor: pointer;
}

// .node-name {
//   font-weight: bold;
// }

.el-icon-arrow-right.collapsed {
  transform: rotate(90deg);
}

.node-children {
  margin-left: 20px;
  //padding-left: 20px; /* 缩进效果 */
}
.xcc{
  margin-left: 620px;
  margin-top: 35px;
}

.node-actions {
  display: inline-block;
  align-items: center;
  margin-bottom: 15px;
  width: 23%;
  margin-top: 12px;
}
.xxx{
  margin-left: -180px;
}

.el-input__inner {
  padding: 2px 5px; /* 调整文本框内边距 */
}

.el-button {
  margin-left: 5px; /* 调整按钮间距 */
}

.tree-node {
  margin-left: 20px;
}


.folder-container {
  display: flex;
  flex-wrap: wrap;
}

.folder-container {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 5%;
  left: 46%; /* 调整左侧边距 */
  width: 60%;
}

.folder-icon {
  width: 80px; /* 将文件夹宽度调整为80px */
  height: 100px; /* 将文件夹高度调整为100px */
  margin: 20px; /* 增加文件夹之间的间距 */
  //background-color: #f5f5f5;
  //border: 1px solid #ccc;
  //border-radius: 5px;
  position: relative;
}

.folder-label {
  position: absolute;
  bottom: -25px; /* 将文件夹标签向下偏移，使其居中显示 */
  left: 0;
  right: 0;
  text-align: center;
  font-size: 16px; /* 将文件夹标签字体大小调整为14px */
}
.vvv{
  position: absolute;
  top: 45%;
  left: 60%; /* 调整左侧边距 */
}

// .folder-label {
//   position: absolute;
//   bottom: -20px;
//   left: 0;
//   right: 0;
//   text-align: center;
//   font-size: 12px;
// }

.folder-container{
  position: absolute;
  top: 2%;
  left: 56%; /* 调整左侧边距 */
  width: 50%;
}

.child-node {
    margin-top: 20px !important;
    transition: height 0.3s ease;
}

.aac {
    font-size: 16px; /* 设置图标大小 */
    margin-right: 5px; /* 设置图标与文本之间的间距 */
    color: #999; /* 设置图标颜色 */
}

.bbc {
    font-size: 86px; /* 设置图标大小 */
    margin-right: 5px; /* 设置图标与文本之间的间距 */
    color: #999; /* 设置图标颜色 */
}

.axx{
  padding-left: 30px;
}
</style>
